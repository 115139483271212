export default {
  namespaced: true,
  state() {
    return {
      profile: {
        id: '',
        username: '',
        nickname: '',
        mobile: '',
        avatar: '',
        token: '',
        score: 0,
        user_id: ''
      },
      redirect_url: '/'
    }
  },
  mutations: {
    SET_PROFILE(state, payload) {
      state.profile = payload
    },
    SET_REDIRECT_URL(state, payload) {
      state.redirect_url = payload
    }
  },
  action: {}
}
